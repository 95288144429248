import React from 'react';
import { NavLink } from 'react-router-dom';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';

import DropDown from '../../components/Form/DropDown';
import { Input } from '../../components/Form/Input';
import { Logo } from '../../components/Logo/Logo';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const progress = { percent: "66%" }
const options = [
  { item: "LA" },
  { item: "MD" },
  { item: "TN" }
]

const FormMatchOtherFields = () => {
  return (
    <div className="p-2 md:p-3">
      <div className="w-[175px] mt-6 mb-8 mx-auto">
        <Logo />
      </div>
      <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto pb-10">
        <div className="absolute w-full">
          <ProgressBar progress={progress.percent} />
        </div>
        <MainCard type="main">
          <div className="grid grid-cols-1 gap-6">
            <div className="mt-6 text-2xl">Additional Information</div>

            <div>
              <Input type="text" label="Date of Birth" />
            </div>
            <div className="grid grid-cols-6 gap-3">
              <div className="col-span-6 md:col-span-5"><Input type="text" label="Driver's License Number (optional)" /></div>
              <div className="col-span-1 hidden md:block"><DropDown options={options} label="State" firstOption="test" /></div>
              <div className="col-span-6 block md:hidden"><DropDown options={options} label="Driver's License State" firstOption="test" /></div>
            </div>
            <div>
              <Input type="text" label="iLevel Student ID (optional)" />
            </div>
            <div>
              <Input type="text" label="Validation Code Provided by Your Employer (optional)" />
            </div>
            <div className="flex flex-row">
              <div>
                <Button type="primary" size="md" href="/#/confirmation">Continue</Button>
              </div>
              <div className="ml-3">
                <Button type="tertiary" size="md" href="/#/form-phone">Back</Button>
              </div>
            </div>
          </div>
        </MainCard>
        <div className="flex flex-row justify-center mt-10">
          <div className="mx-3"><NavLink to="/match">Match</NavLink></div>
        </div>
      </div>
    </div>
  );
};

export default FormMatchOtherFields;
