// Package Dependencies
import axios from "axios";
// Additional Dependencies
import Config from "../config/config";

// Function to Get iLevel Access Token from Session Storage
const getAccessToken = () => {
  // Get iLevel Access Token from Session Storage
  const token = sessionStorage.getItem("ilevel:access-token");
  // Check if iLevel Access Token is Set
  if (!token) {
    // Log Warning
    console.warn("iLevel Access Token is Not Set in Session Storage");
    // Return Null
    return null;
  }
  return token;
};

// Define Variable to Hold iLevel Access Token
const accessToken = getAccessToken();

// Intialize Axios Client
const client = axios.create({
  baseURL: Config.api.endpoint,
  headers: {
    Authorization: `Bearer ${accessToken === null ? "null" : accessToken}`,
    "x-api-key": Config.api.key,
  },
});

// Export
const Axios = {
  // POST Request
  post_request: async (url, payload) => {
    try {
      const response = await client.post(url, payload);
      if (response.status !== 200) {
        throw new Error(`Axios Utility XHR Error: ${response.status} - ${response.statusText}`);
      }
      return response;
    } catch (error) {
      throw error.response || error;
    }
  },
  // GET Request
  get_request: async (url) => {
    try {
      const response = await client.get(url);
      if (response.status !== 200) {
        throw new Error(`Axios Utility XHR Error: ${response.status} - ${response.statusText}`);
      }
      return response;
    } catch (error) {
      throw error.response || error;
    }
  },
  // PUT Request
  put_request: async (url, payload) => {
    try {
      const response = await client.put(url, payload);
      if (response.status !== 200) {
        throw new Error(`Axios Utility XHR Error: ${response.status} - ${response.statusText}`);
      }
      return response;
    } catch (error) {
      throw error.response || error;
    }
  },
  // PATCH Request
  patch_request: async (url, payload) => {
    try {
      const response = await client.patch(url, payload);
      if (response.status !== 200) {
        throw new Error(`Axios Utility XHR Error: ${response.status} - ${response.statusText}`);
      }
      return response;
    } catch (error) {
      throw error.response || error;
    }
  },
  // DELETE Request
  delete_request: async (url) => {
    try {
      const response = await client.delete(url);
      if (response.status !== 200) {
        throw new Error(`Axios Utility XHR Error: ${response.status} - ${response.statusText}`);
      }
      return response;
    } catch (error) {
      throw error.response || error;
    }
  },
};

export default Axios;
