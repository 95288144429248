
const TrainingHistory = [
	{
        "course_id": 485733,
        "course_name": "COSS Recertification - Late",
				"course_type": "Course",
				"prerequisits": 0,
				"date": "Today",
				"expiration": "07/12/2022",
				"duration": "1 hour",
				"location": "Alliance Safety Council - Baton Rouge, LA",
        "availability": 1
    },
    {
        "course_id": 873454,
        "course_name": "Alliance Drug Screen",
				"course_type": "Drug Screen",
				"prerequisits": 1,
				"date": "Today",
				"expiration": "07/12/2022",
				"duration": "1 hour",
				"location": "Alliance Safety Council - Baton Rouge, LA",
        "availability": 3
	},
    {
        "course_id": 198573,
        "course_name": "Basic Orientation Plus - ARSC Reciprocal",
				"course_type": "Course",
				"prerequisits": 0,
				"date": "Today",
				"expiration": "07/12/2022",
				"duration": "1 hour",
				"location": "Alliance Safety Council - Baton Rouge, LA",
        "availability": 6
	},
    {
        "course_id": 293854,
        "course_name": "COSS Extention",
				"course_type": "Course",
				"prerequisits": 2,
				"date": "Tommorow",
				"expiration": "07/12/2022",
				"duration": "1 hour",
				"location": "Alliance Safety Council - Baton Rouge, LA",
        "availability": 9
	},
	{
			"course_id": 398588,
			"course_name": "Hands-On Fire Extinguisher Training - ARSC Reciprocal",
			"course_type": "Course",
			"prerequisits": 2,
			"date": "Tommorow",
			"expiration": "07/12/2022",
			"duration": "1 hour",
			"location": "Alliance Safety Council - Baton Rouge, LA",
			"availability": 12
	},
	{
			"course_id": 398853,
			"course_name": "Supplied Air Attendant - ARSC Reciprocal",
			"course_type": "Course",
			"prerequisits": 0,
			"date": "Tommorow",
			"expiration": "07/12/2022",
			"duration": "1 hour",
			"location": "Alliance Safety Council - Baton Rouge, LA",
			"availability": 20
	},
	{
			"course_id": 258345,
			"course_name": "Basic Orientation Plus Refresher - ARSC Reciprocal",
			"course_type": "Course",
			"prerequisits": 0,
			"date": "Tommorow",
			"expiration": "07/12/2022",
			"duration": "1 hour",
			"location": "Alliance Safety Council - Baton Rouge, LA",
			"availability": 4
	},
	{
			"course_id": 297448,
			"course_name": "COSS Recertification",
			"course_type": "Course",
			"prerequisits": 0,
			"date": "Tommorow",
			"expiration": "07/12/2022",
			"duration": "1 hour",
			"location": "Alliance Safety Council - Baton Rouge, LA",
			"availability": 16
	},
	{
			"course_id": 388533,
			"course_name": "Fire Watch - ARSC Reciprocal",
			"course_type": "Course",
			"prerequisits": 0,
			"date": "Tommorow",
			"expiration": "07/12/2022",
			"duration": "1 hour",
			"location": "Alliance Safety Council - Baton Rouge, LA",
			"availability": 22
	},
	{
			"course_id": 985773,
			"course_name": "Gas Monitoring - ARSC Reciprocal",
			"course_type": "Course",
			"prerequisits": 0,
			"date": "4/10/2022",
			"expiration": "07/12/2022",
			"duration": "1 hour",
			"location": "Alliance Safety Council - Baton Rouge, LA",
			"availability": 18
	},
	{
			"course_id": 837754,
			"course_name": "Confined Spaces - ARSC Reciprocal",
			"course_type": "Course",
			"prerequisits": 3,
			"date": "4/10/2022",
			"expiration": "07/12/2022",
			"duration": "1 hour",
			"location": "Alliance Safety Council - Baton Rouge, LA",
			"availability": 9
	},
	{
			"course_id": 867643,
			"course_name": "Advanced Confined Space",
			"course_type": "Course",
			"prerequisits": 0,
			"date": "4/10/2022",
			"expiration": "07/12/2022",
			"duration": "1 hour",
			"location": "Alliance Safety Council - Baton Rouge, LA",
			"availability": 14
	},
	{
			"course_id": 877572,
			"course_name": "Certified Human Performance Instructor-led",
			"course_type": "Course",
			"prerequisits": 1,
			"date": "4/10/2022",
			"expiration": "07/12/2022",
			"duration": "1 hour",
			"location": "Alliance Safety Council - Baton Rouge, LA",
			"availability": 25
	},
]

export default TrainingHistory;
