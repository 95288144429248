import React, { Component } from 'react';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';
import PageTitle from '@alliancesafetycouncil/asc-page-title';

import BackgroundImg from '../../assets/images/background.png';
import Avatar from '../../components/Avatar/Avatar';
import TopNav from '../../components/Navigation/TopNav';
import ProfilePanel from '../../components/Panels/ProfilePanel';

const user = {
  name: 'Tom Cook',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

class MyProfile extends Component {

  render() {

    const data = [
      { title: 'Training History', icon: "fal fa-users-class", subTitle: null, description: 'View all training you have received in the past.', link: '/training-history' },
      { title: 'Digital Badge', icon: "fal fa-id-badge", subTitle: null, description: 'Print your own badge from anywhere.', link: 'badge' },
      { title: 'Employment', icon: "fal fa-briefcase", subTitle: 'Eastman Kodak Chemical, Inc.', description: 'Edit and view history of your employment.', link: '/employment' },
      { title: 'Payment Methods', icon: "fal fa-credit-card", subTitle: null, description: 'View all of the credit cards you have saved.', link: '/payment-methods' },
      { title: 'Sign In & Security', icon: "fal fa-lock-alt", subTitle: null, description: 'Change your password and security questions.', link: '/security' },
      { title: 'Driver License', icon: "fal fa-id-card", subTitle: null, description: 'Upload an image of your license to update it.', link: '/drivers-license' },
      { title: 'Receipts', icon: "fal fa-receipt", subTitle: null, description: 'List of Receipts.', link: 'license' },
      { title: 'Certificates', icon: "fal fa-badge-check", subTitle: null, description: 'Print certificates you have earned.', link: 'certificates' }

    ];

    return (
      <div className="flex flex-col bg-blue-50 h-screen">
        <div className="fixed w-full z-20">
          <TopNav />
        </div>
        <div className="spacer-for-nav mt-17"></div>
        {/* Page Title wrapper */}
        <div className="flex-initial mx-4 mt-4 mb-1 flex items-center">
          <PageTitle>
            <i className="fal fa-user text-orange-500 mr-2"></i>My Profile
          </PageTitle>
        </div>
        {/* Main Card wrapper */}
        <div className="m-2 sm:m-3 pb-3">
          <MainCard type="main">
            <div
              style={{
                backgroundImage: `url(${BackgroundImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '95px',
                minHeight: '95px'
              }}
              className="rounded-t-lg relative"
            >
              <div className="absolute left-8 top-3">
                <Avatar img={user.imageUrl} size="125px" color="gray-500" />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-7 border border-gray-300 border-t-0 rounded-b-lg gap-3 pt-12 pl-8 pr-3 pb-3">
              <div className="md:col-span-2 lg:col-span-1 xl:col-span-2">
                <div className="text-2xl">
                  Johnathon Williams
                </div>
                <div className="text-gray-400">
                  Master Welder
                </div>
              </div>
              <div className="hidden md:block lg:col-span-1 xl:col-span-2">
                123 E Main Street<br />
                Lancaster, PA 12345
              </div>
              <div className="xl:col-span-2 hidden md:block">
                443-555-1212<br />
                <a href="mailto:jdoe@email.com">jdoe@email.com</a>
              </div>
              <div className="flex justify-start md:col-span-2 lg:col-span-1 xl:justify-end">
                <Button type="secondary" size="md" href="/#/edit-my-profile">
                  Edit
                </Button>
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">

              {data.map((data) => (
                data.title !== null
                  ? (
                    <ProfilePanel
                      title={data.title}
                      subTitle={data.subTitle}
                      description={data.description}
                      icon={data.icon}
                      link={data.link}
                    />
                  )
                  : null
              ))}


            </div>
          </MainCard>
        </div>
      </div>
    );
  }
}

export default MyProfile;
