import React from 'react';
import { NavLink } from 'react-router-dom';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';

import { Input } from '../../components/Form/Input';
import { Logo } from '../../components/Logo/Logo';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const progress = { percent: "33%" }

const ValidatePhone = () => {
  return (
    <div className="p-2 md:p-3">
      <div className="w-[175px] mt-6 mb-8 mx-auto">
        <Logo />
      </div>
      <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto pb-10">
        <div className="absolute w-full">
          <ProgressBar progress={progress.percent} />
        </div>
        <MainCard type="main">
          <div className="grid grid-cols-1 gap-6">
            <div className="mt-6 text-2xl">Check Your Phone</div>
            <div className="text-blue-500 flex flex-row items-center p-3 rounded-lg bg-blue-100">
              <div>
                <i class="fas fa-check-circle text-xl mr-1"></i>
              </div>
              <div>
                We sent a 6-digit security code to ***-***-1147.
              </div>
            </div>
            <div>
              <Input type="number" label="6-digit Code" />
            </div>
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <Button type="primary" size="md" href="/#/form-other">Continue</Button>
              </div>
              <div className="mr-3">
                <Button type="tertiary" size="md" href="/#/form-phone">Back</Button>
              </div>
              <div >
                <NavLink to="/#/">Resend Code</NavLink>
              </div>
            </div>
          </div>
        </MainCard>
        <div className="flex flex-row justify-center mt-10">
          <div className="mx-3"><NavLink to="/match">Match</NavLink></div>
        </div>
      </div>
    </div>
  );
};

export default ValidatePhone;
