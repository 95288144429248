import React, { useState } from "react";
import styled from "styled-components";
import IdVerificationPersona from "../../components/IDVerification/IDVerificationPersona";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
`;

const StepCard = styled.div`
  background-color: #e6f7ff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
`;

const GetStartedButton = styled.button`
  background-color: #b6e0fe;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  display: block;
  box-sizing: border-box;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const CourseRequirements = () => {
  const [paymentInfo, setPaymentInfo] = useState("");
  const [personalInfo, setPersonalInfo] = useState("");
  const [governmentId, setGovernmentId] = useState(null);
  const [consent, setConsent] = useState(false);
  const [init, setInit] = useState(false);

  const handlePaymentInfoChange = (e) => setPaymentInfo(e.target.value);
  const handlePersonalInfoChange = (e) => setPersonalInfo(e.target.value);
  const handleGovernmentIdChange = (e) => setGovernmentId(e.target.files[0]);
  const handleConsentChange = (e) => setConsent(e.target.checked);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submission logic
    console.log(paymentInfo, personalInfo, governmentId, consent);
  };

  return (
    <ModalOverlay>
      <ModalContainer>
        <h2>Course Requirements</h2>
        <p>
          Before you begin, this course requires that you take the following
          actions. It’s easy and only takes a minute.
        </p>
        <form onSubmit={handleSubmit}>
          <StepCard>
            <Label htmlFor="payment">
              Step 1: Provide payment information.
            </Label>
            <Input
              type="text"
              id="payment"
              value={paymentInfo}
              onChange={handlePaymentInfoChange}
            />
          </StepCard>
          <StepCard>
            <Label htmlFor="personal-info">
              Step 2: Enter information about yourself.
            </Label>
            <Input
              type="text"
              id="personal-info"
              value={personalInfo}
              onChange={handlePersonalInfoChange}
            />
          </StepCard>
          {/* <StepCard>
            <Label htmlFor="government-id">Step 3: Confirm your identity.</Label>
            <Input
              type="file"
              id="government-id"
              onChange={handleGovernmentIdChange}
            />
          </StepCard> */}
          <StepCard>
            <Label htmlFor="government-id">
              Step 3: Confirm your identity.
            </Label>
            {/* Replace the input field with the IdVerificationPersona component */}
            {init ? (
              <IdVerificationPersona />
            ) : (
              <button onClick={() => setInit(true)}>init</button>
            )}
          </StepCard>
          <StepCard>
            <Label htmlFor="consent">Step 4: Sign a consent form.</Label>
            <Input
              type="checkbox"
              id="consent"
              checked={consent}
              onChange={handleConsentChange}
            />
            <Label htmlFor="consent">
              You are simply agreeing you are who you say you are.
            </Label>
          </StepCard>
          <GetStartedButton type="submit">Get Started</GetStartedButton>
        </form>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default CourseRequirements;
