import React from 'react';
import { NavLink} from 'react-router-dom';

const CartEmpty = () => {

  return (
    <div className="p-20 text-center">
      <div><i className="fa-light fa-shopping-cart text-5xl text-orange-500"></i></div>
      <div className="text-2xl my-4">Your shopping cart is empty.</div>
      <NavLink to="/catalog">Continue Shopping</NavLink>
    </div>
  );
};

export default CartEmpty;
