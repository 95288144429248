import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Persona from "persona";
import jwtDecode from 'jwt-decode';
import Loading from "../Loading/Loading";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import { retrieveStudentIDVerificationInquiryRequest } from "../../action/Student/RetrieveStudentIDVerificationInquiry";
import Config from "../../config/config";

const IDVerificationPersona = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [retryAttempt, setRetryAttempt] = useState(1);
  const [retryMax] = useState(3);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const refreshToken = useRef(null);
  const accessToken = useRef(null);
  const userInfo = useRef(null);
  const personaId = useRef(null);
  const companyId = useRef(null);
  const studentId = useRef(null);
  const tenantId = useRef(null);
  const inquiryId = useRef(null);
  const dispatch = useDispatch();
  const retrieveStudentIDVerificationInquiryReducer = useSelector(
    (state) => state.retrieveStudentIDVerificationInquiryReducer
  );

  // Console Logging
  console.info(`ID Verification Attempt ${retryAttempt} of ${retryMax}`);

  // ----- AUTHENTICATION: BEGIN ------------------------------------------------------------------------
  // TODO: Refactor to React Component

  // Function to Store the Access Token in Session Storage
  const storeAccessToken = (token) => {
    const sessionStorageKey = "ilevel:access-token";
    sessionStorage.setItem(sessionStorageKey, token);
    console.info(`Stored iLevel Access Token as ${sessionStorageKey} in Session Storage`);
  }

  // Function to Store the User Information in Session Storage
  const storeUserInfo = (userInfo) => {
    const sessionStorageKey = "ilevel:user-info";
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(userInfo));
    console.info(`Stored iLevel User Info as ${sessionStorageKey} in Session Storage`);
  }

  // Function to Fetch User Information from Identity Server Using the Access Token
  const fetchUserInfo = async (accessToken, userInfoRef) => {
    try {
      console.info('Fetching User Info using iLevel Access Token');
      const response = await fetch(`${Config.auth.ilevel.authority}/connect/userinfo`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      // Parse User Information Response as JSON
      const userInfo = await response.json();
      // Store User Information in Ref
      userInfoRef.current = userInfo;

      // Store the Access Token and User Info in Session Storage
      storeAccessToken(accessToken);
      storeUserInfo(userInfo);

      // Authentication Success
      console.info('iLevel Access Token Authentication Success!');
      return true;
    } catch (error) {
      // Set Error State
      setError(true);
      // Authentication Failed
      console.error('Error Fetching User Info using iLevel Access Token:', error);
      console.warn('iLevel Access Token Authentication Failed!');
      return false;
    }
  }

  // Function to Authenticate using iLevel Access Token Method
  const iLevelAccessTokenAuth = async () => {
    try {
      console.info('Authenticating using iLevel Access Token Method...');
      if (jwtDecode(accessToken.current)) {
        console.info('Confirmed iLevel Access Token is a Valid JWT Token');
        const response = await fetchUserInfo(accessToken.current, userInfo);
        // Returning True to Indicate Successful Authentication
        setIsAuthenticated(response);
      }
    } catch (error) {
      // Set Error State
      setError(true);
      console.error('iLevel Access Token is an Invalid JWT Token:', error.message);
      // Returning False to Indicate Failed Authentication
      setIsAuthenticated(false);
    }
  };

  // ----- AUTHENTICATION: END --------------------------------------------------------------------------

  // Function to Handle Loading
  const handleLoading = (mode) => {
    switch (mode) {
      case true:
        setLoading(true);
        break;
      case false:
        setLoading(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        const { data: message, origin } = event;
        const { ilevel, persona } = Config.service;

        if (origin === ilevel.origin || origin === persona.origin) {
          if (origin === ilevel.origin) {
            console.info("Received Message from iLevel Origin:", message);

            if (typeof message !== 'object' || message === null) {
              throw new Error("Invalid Message Structure from iLevel Origin");
            }

            refreshToken.current = message.auth.refreshToken ?? null;
            console.info(`Setting Refresh Token from iLevel: ${accessToken.current}`);
            accessToken.current = message.auth.accessToken ?? null;
            console.info(`Setting Access Token from iLevel: ${accessToken.current}`);
            iLevelAccessTokenAuth();

            companyId.current = message.companyId ?? null;
            studentId.current = message.studentId ?? null;
            tenantId.current = message.tenantId ?? null;

            console.info(`Setting IDs Fields from iLevel Origin`);
            console.info(`Company ID: ${companyId.current}, Student ID: ${studentId.current}, Tenant ID: ${tenantId.current}`);

            personaId.current = message.companyId && message.studentId && message.tenantId
              ? `${message.companyId}-${message.studentId}-${message.tenantId}`
              : null;
            console.info(`Setting Persona ID from iLevel: ${personaId.current}`);
          }

          if (origin === persona.origin) {
            console.info("Received Message from Persona Origin:", message);

            if (typeof message !== 'object' || message === null) {
              throw new Error("Invalid Message Structure from Persona Origin");
            }

            if (message.name === "start") {
              inquiryId.current = message.metadata?.inquiryId ?? null;
              console.info(`Setting Inquiry ID from Persona 'start' Message: ${inquiryId.current}`);
            }
          }
        } else {
          console.warn("Received Message from an Untrusted Origin:", origin);
        }
      } catch (error) {
        console.error("Error Handling Message:", error);
        setError(true);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (retrieveStudentIDVerificationInquiryReducer) {
      const { response, data, error } = retrieveStudentIDVerificationInquiryReducer;

      if (response && data) {
        console.debug(`retrieveStudentIDVerificationInquiryReducer.response: ${JSON.stringify(response)}`);
        console.debug(`retrieveStudentIDVerificationInquiryReducer.data: ${JSON.stringify(data)}`);

        if (data.status !== 'approved') {
          setError(true);
          console.error(`Unexpected retrieveStudentIDVerificationInquiryReducer.data: ${JSON.stringify(data)}`);
        }

        console.info(`Sending Message to iLevel Origin to Handle Persona onComplete State`);
        try {
          window.parent.postMessage(data, Config.service.ilevel.origin);
        } catch (postMessageError) {
          console.error(`Sending Message to iLevel Origin: ${postMessageError}`);
        }
      }

      if (error) {
        console.error(`Unexpected retrieveStudentIDVerificationInquiryReducer.error: ${error}`);
        setError(true);
      }
    } else {
      console.warn(`retrieveStudentIDVerificationInquiryReducer is 'null' or 'undefined'`);
    }
  }, [retrieveStudentIDVerificationInquiryReducer]);

  return (
    <div>
      <div className="relative" style={{ height: "600px" }}>
        {loading && (
          <div
            className="absolute"
            style={{
              background: "#c3c3c350",
              left: 0,
              top: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Loading delay={500} />
          </div>
        )}
        {error ? (
          <div
            className="absolute"
            style={{
              background: "white",
              left: 0,
              top: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <ErrorComponent setError={setError} setLoading={setLoading} setRetryAttempt={setRetryAttempt} retryAttempt={retryAttempt} retryMax={retryMax} controls="tryAgain" />
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center h-full pb-20">
            {isAuthenticated && personaId.current && (
              <Persona.Inquiry
                referenceId={personaId.current}
                templateId={Config.service.persona.templateId}
                environmentId={Config.service.persona.environmentId}
                onLoad={() => {
                  document.getElementsByClassName(
                    "persona-widget__iframe"
                  )[0].height = "600px";
                  document.getElementsByClassName(
                    "persona-widget__iframe"
                  )[0].width = "100%";
                }}
                onReady={() => { handleLoading(false); }}
                onComplete={({ status, fields }) => {
                  // Set Loading State
                  handleLoading(true);
                  // Handle Persona onComplete Event
                  // *** Log Persona onComplete Data Fields ***
                  console.debug(`Persona onComplete Data Fields:`);
                  console.debug(`Log status: ${status}`);
                  console.debug(`Log fields: ${fields}`);

                  // *** Log Persona Origin Data Fields ***
                  console.debug(`Log Persona Origin Data Fields:`);
                  console.debug(`Log inquiryId: ${inquiryId.current}`);

                  // *** Log iLevel Origin Data Fields ***
                  console.debug(`Log iLevel Origin Data Fields:`);
                  console.debug(`Log companyId: ${companyId.current}`);
                  console.debug(`Log studentId: ${studentId.current}`);
                  console.debug(`Log tenantId: ${tenantId.current}`);

                  // Error Handling
                  if (!companyId.current || !studentId.current || !inquiryId.current) {
                    setError(true);
                    console.warn(`Error: Required ID Fields Not Set`);
                  }

                  // Redux Actions
                  // Dispatching Redux Action: retrieveStudentIDVerificationInquiryRequest()
                  console.log(`Dispatching Redux Action: retrieveStudentIDVerificationInquiryRequest()`);
                  dispatch(
                    retrieveStudentIDVerificationInquiryRequest({
                      company_id: companyId.current ? companyId.current : null,
                      student_id: studentId.current ? studentId.current : null,
                      inquiry_id: inquiryId.current ? inquiryId.current : null,
                      tenant_id: tenantId.current ? tenantId.current : null
                    })
                  );

                  // Handle Persona Status
                  // Persona Inquiry Completed Status
                  if (status === "completed") {
                    console.info(`Received Completed State for Persona Inquiry ID: ${inquiryId.current}`);
                  }
                }}
                onError={() => setError(true)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default IDVerificationPersona;
