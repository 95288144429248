import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'

const BasicModal = ({ children, ...props }) => {
    const [open, setOpen] = useState(false)

    const ButtonComponent = props.buttonName;
    console.log(props.buttonName);

    return (
        <div>
            <Link to="#" onClick={() => setOpen(true)}>
                <props.buttonComponent />
            </Link>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                        {/* <div className="pointer-events-none fixed inset-y-0 left-0 flex w-full mb-0 md:mb-4"> */}
                        <div className="pointer-events-none fixed inset-y-0 left-0 w-full">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-200"
                                leave="transform transition ease-in-out duration-300"
                                leaveFrom="opacity-200"
                                leaveTo="opacity-0"
                            >
                                <div className="m-3 inset-0 absolute">
                                    <Dialog.Panel 
                                    // style={{maxHeight: 'calc(100% - 60px)'}}
                                    className="
                                        pointer-events-auto 
                                        relative 
                                        md:w-[510px]
                                        md:mt-16 
                                        bg-white 
                                        shadow-xl 
                                        rounded-xl 
                                        mx-auto
                                        flex
                                        flex-col
                                        overflow-hidden
                                        max-h-[calc(100%-5px)]
                                        md:max-h-[calc(100%-60px)]
                                    ">
                                        <Dialog.Title className="flex flex-row items-center border-b border-gray-300 p-4">
                                            <div className="flex items-center text-lg font-medium flex-grow">
                                                <i className={`fa-light fa-${props.icon} mr-2 text-3xl text-orange-500`}></i>
                                                {props.title}
                                            </div>
                                            <div>
                                                <Link to="#" onClick={() => setOpen(false)} className="outline-none focus:outline-none">
                                                    <i className="fa-light fa-times text-xl"></i>
                                                </Link>
                                            </div>
                                        </Dialog.Title>
                                        <div className="flex flex-col overflow-y-scroll">
                                            <div className="relative pt-4 pb-5 px-4">
                                                <div>
                                                    {children}
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </div>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
};

export default BasicModal;
