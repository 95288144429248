import React, { useCallback } from "react";
import styles from "./ErrorComponent.module.css";

const ErrorComponent = ({ setError, setLoading, setRetryAttempt, retryAttempt, retryMax, controls }) => {
  const handleRetry = useCallback(() => {
    setRetryAttempt((prevRetryAttempt) => {
      if (prevRetryAttempt < retryMax) {
        setLoading(true);
        setError(false);
        return prevRetryAttempt + 1;
      }
      return prevRetryAttempt;
    });
  }, [setRetryAttempt, setLoading, setError, retryMax]);

  const closeAndUnload = useCallback(() => window.close(), []);

  const controlKeys = controls.split(',');

  const controlMap = {
    tryAgain: (
      <button className={styles.tryAgain} onClick={handleRetry} key="tryAgain">
        Try Again
      </button>
    ),
    close: (
      <button className={styles.close} onClick={closeAndUnload} key="close">
        Close
      </button>
    )
  };

  const Controls = React.memo(() => (
    <div className={styles.controls}>
      {controlKeys.map((key) => (
        controlMap[key]
      ))}
    </div>
  ));

  return (
    <div className={styles.errorComponent}>
      <div className={styles.errorBox}>
        <p className={styles.errorMessage}>
          We could not verify your identity.
        </p>
        <p className={styles.errorMessage}>
          If you believe you have received this message in error, please close this popup window and 'Submit Help Ticket' from 'Help' in the main menu.
        </p>
        {retryAttempt < retryMax && <Controls />}
      </div>
    </div>
  );
};

export default React.memo(ErrorComponent);
