import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TopNav from "../../components/Navigation/TopNav";
import CourseDetail from "../../components/Panels/CourseDetail";
// import Tools from "../../components/ContentTools/CourseTools"; Post MVP
import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Button from "@alliancesafetycouncil/asc-button";
import { BreadCrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import BasicModal from "../../components/Modal/BasicModal";
import IdVerification from "../../components/IDVerification/IDVerification";
import IdVerificationPersona from "../../components/IDVerification/IDVerificationPersona";

const getStartedButton = () => {
  return (
    <Button type="primary" size="md" href="/#/">
      Get Started
    </Button>
  )
}

const baseURL = "https://62ab6284bd0e5d29af0f1cce.mockapi.io/Catalog";

export default function Details(props) {

  const { id } = useParams();
  const [course, setCourse] = useState(null);


  useEffect(() => {
    axios.get(`${baseURL}?id=${id}`).then((response) => {
      setCourse(response.data);
    });
  }, [id]);

  if (!course) return null;

  const links = [
    {
      title: <div><i class='fa-solid fa-chevron-left text-xs'></i> Back to results</div>,
      link: "/#/catalog"
    }
  ];
  return (
    <div>
      <div className="fixed w-full z-10">
        <TopNav />
      </div>

      <div className="spacer-for-nav h-17"></div>
      <div className="p-2 lg:p-4 mt-2 lg:mt-0">
        <div className="ml-2 my-1">
          <BreadCrumbs crumbs={links} />
        </div>
        {course.map((item) => (
          <div key={item.id}>
            <div className="mb-4 flex flex-row md:items-center">
              <div>
                <PageTitle>
                  <i className="fa-regular fa-book-open text-orange-500 mr-2"></i>
                  {item.title}
                </PageTitle>
              </div>
              <div>
                {item.enrolled ? (
                  <div className="flex">
                    {/* <div className="md:bg-green-500 md:rounded-lg mb-1 md:mb-0 md:py-1 md:px-2">
                      <div className="text-sm md:text-base text-green-500 font-medium md:font-normal md:text-white"><i class="fa-light fa-check mr-1 md:mr-2"></i>Enrolled</div>
                    </div> */}
                    <div className="bg-green-500 rounded-lg py-1 px-2">
                      <div className="text-sm text-white flex items-center">
                        <i class="fa-light fa-check mr-1"></i>Enrolled
                      </div>
                    </div>
                  </div>
                ) : null
                }
              </div>
            </div>
            <MainCard type="main">
              <div className="bg-black rounded-lg">
                <div className="relative w-full overflow-hidden rounded-lg lg:rounded-none flex justify-center">
                  {item.enrolled ? (
                    <div>
                      {/* Some content */}
                      {item.idVerification === true && item.verified !==true ? (
                        <div className="w-[954px] relative">

                          <div className="absolute bg-black opacity-75 top-0 bottom-0 left-0 right-0"></div>
                          <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col gap-6 items-center justify-center">
                            <div className="text-lg w-4/5 md: w-full md:text-xl flex flex-col sm:flex-row items-center justify-center">
                              <div className="h-10 w-10 rounded-full flex justify-center items-center bg-white mr-2">
                                <i class="fa-light fa-id-card text-black text-xl"></i>
                              </div>
                              <div className="text-white">
                                This Course requires ID confirmation.
                              </div>
                            </div>
                            <div>
                              <BasicModal
                                title="Confirm Identity"
                                icon="id-card"
                                buttonComponent={getStartedButton}
                              >
                                <div>
                                  <IdVerificationPersona />
                                  
                                </div>
                              </BasicModal>
                            </div>
                          </div>

                          <iframe
                            width="100%"
                            title={item.title}
                            className="h-48 sm:h-[460px]"
                            src={item.video}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                      ) : (
                        <div className="w-[954px] relative">
                          <iframe
                            width="100%"
                            title={item.title}
                            className="h-48 sm:h-[460px]"
                            src={item.video}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                      )}
                    </div>
                  ) : (
                    <img
                      className="w-full h-40 sm:h-80 lg:h-96 object-center object-cover rounded-lg"
                      src={item.img}
                      alt={item.title}
                    />
                  )}

                </div>
              </div>
              <div className="text-xl font-medium mb-3 mt-6">
                Course Details
              </div>
              <div>
                <CourseDetail
                  id={item.id}
                  img={item.img}
                  type={item.type}
                  background={item.background}
                  icon={item.icon}
                  duration={item.duration}
                  release={item.release}
                  rating={item.rating}
                  ratingCount={item.ratingCount}
                  description={item.description}
                  title={item.title}
                  enrolled={item.enrolled}
                  price={item.price}
                />
              </div>

            </MainCard>
          </div>
        ))}
      </div>
    </div>
  );
}
