import React from 'react';

const ProgressBar = ({progress, shape}) => {

  return (
    <div className="flex flex-row items-center">
      <div className={`bg-green-500 h-3 z-10 ${shape !== 'square' ? 'rounded-tl-xl' : 'rounded-none'}`} style={{width: `${progress}`}}></div>
      <div className={`bg-gray-300 h-3 ${shape !== 'square' ? 'rounded-tr-xl' : 'rounded-none'}`} style={{width: `calc(100% - ${progress})`}}></div>
    </div>
  );
};

export default ProgressBar;
