import React, { Component } from "react";
import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Button from "@alliancesafetycouncil/asc-button";
import { BreadCrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import TopNav from '../../components/Navigation/TopNav';

class Security extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) { this.setState({ value: event.target.value }); }
  handleSubmit(event) {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  render() {

    const links = [
      {
        title: "My Profile",
        link: "/#/my-profile",
      },
      {
        title: "Sign In & Security",
      },
    ];

    const data = { question: 'What is the name of your first pet?', answer: "Fluffy" };

    return (
      <div className="flex flex-col bg-blue-50 h-screen">
        <div className="fixed w-full z-20">
          <TopNav />
        </div>
        <div className="spacer-for-nav h-16"></div>
        {/* Page Title wrapper */}
        <div className="flex-initial mx-4 mb-1 mt-4 flex flex-col">
          <div className="ml-2 my-2">
            <BreadCrumbs crumbs={links} />
          </div>
          <PageTitle>
            <i className="fal fa-lock-alt text-orange-500 mr-2"></i>Sign In & Security
          </PageTitle>
        </div>
        {/* Main Card wrapper */}
        <div className="m-2 sm:m-3 pb-3">
          <MainCard type="main">
            <form>
              <div className="pb-2 border-b border-gray-300 mb-6">
                <div className="text-xl flex flex-grow">Change Password</div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    Current Password
                  </div>
                  <div>
                    <input
                      class="inline-flex w-full rounded border border-gray-400 shadow-sm px-4 py-2 bg-white text-gray-900 hover:bg-blue-50"
                      id="password"
                      defaultValue=""
                      onChange={this.handleChange}
                      type="password" />
                  </div>
                </div>

                <div className="col-start-1">
                  <div className="mb-2 text-sm font-medium text-gray-700 leading">
                    New Password
                  </div>
                  <div>
                    <input
                      type="password"
                      defaultValue=""
                      onChange={this.handleChange}
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-4 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>

                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    Confirm Password
                  </div>
                  <div>
                    <input
                      type="password"
                      defaultValue=""
                      onChange={this.handleChange}
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-4 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>
              </div>


              <div className="pb-2 border-b border-gray-300 mb-6 mt-8">
                <div className="text-xl flex flex-grow">Chage Security Question / Answer</div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    Select a Security Question
                  </div>
                  <div>
                    <select class="inline-flex w-full rounded border border-gray-400 shadow-sm px-4 py-2 bg-white text-gray-900 hover:bg-blue-50">
                      <option>{data.question}</option>
                      <option>Question #2</option>
                      <option>Question #3</option>
                    </select>
                  </div>
                </div>

                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700 leading">
                    Answer
                  </div>
                  <div>
                    <input
                      type="text"
                      defaultValue={data.answer}
                      onChange={this.handleChange}
                      id="answer"
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-4 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row mt-5">
                <div className="mr-3">
                  <Button type="save" size="md" href="/my-profile">
                    Save
                  </Button>
                </div>
                <div>
                  <Button type="tertiary" size="md" href="/my-profile">
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </MainCard>
        </div>
      </div>
    );
  }
}

export default Security;
