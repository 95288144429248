
const CompanyList = [
	{
        "company_id": 485733,
        "company_name": "Alliance Safety Council"
    },
    {
        "company_id": 873454,
        "company_name": "Eastern Alliance Insurance Group, Inc."
	},
    {
        "company_id": 198573,
        "company_name": "Eastman Kodak Chemical, Inc."
	},
    {
        "company_id": 293854,
        "company_name": "Mission Support Alliance"
	},
	{
			"company_id": 398588,
			"company_name": "Northern American Process Technology"
	},
	{
			"company_id": 398853,
			"company_name": "Ponka Engineering, Inc."
	},
	{
			"company_id": 258345,
			"company_name": "Power Delivery Alliance"
	},
	{
			"company_id": 297448,
			"company_name": "Stallings Industries"
	},
	{
			"company_id": 388533,
			"company_name": "Tony Parent Enterprises, Inc."
	},
	{
			"company_id": 985773,
			"company_name": "Total Maritime Services"
	},
	{
			"company_id": 837754,
			"company_name": "Travelers Groups"
	},
	{
			"company_id": 867643,
			"company_name": "United Services Systems"
	},
	{
			"company_id": 877572,
			"company_name": "John Doe"
	},
]

export default CompanyList;
