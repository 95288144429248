import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Catalog from "./views/Catalog";
import CourseDetail from "./views/Catalog/CourseDetails";
import PathDetail from "./views/Catalog/PathDetails";
import Cart from "./views/Checkout/Cart";
import Confirmation from "./views/LinkAccount/Confirmation";
import EmailValidated from "./views/LinkAccount/EmailValidated";
import FormAddress from "./views/LinkAccount/FormAddress";
import FormEmail from "./views/LinkAccount/FormEmail";
import FormMatchOtherFields from "./views/LinkAccount/FormMatchOtherFields";
import FormPassword from "./views/LinkAccount/FormPassword";
import FormPhone from "./views/LinkAccount/FormPhone";
import Match from "./views/LinkAccount/Match";
import ValidateEmail from "./views/LinkAccount/ValidateEmail";
import ValidatePhone from "./views/LinkAccount/ValidatePhone";
import Dashboard from "./views/Dashboard";
import SignIn from "./views/SignIn";
import CourseRequirements from "./views/CourseRequirements/CourseRequirements";
import MyProfile from "./views/MyProfile";
import EditMyProfile from "./views/MyProfile/EditMyProfile";
import TrainingHistory from "./views/MyProfile/TrainingHistory";
import Employment from "./views/MyProfile/Employment";
import PaymentMethods from "./views/MyProfile/PaymentMethods";
import Security from "./views/MyProfile/Security";
import DriversLicense from "./views/MyProfile/DriversLicense";
import EmbeddedAppComponent from "./components/EmbeddedAppComponent/EmbeddedAppComponent";
import { AuthProvider, useAuth } from "oidc-react";
import { useSelector, useDispatch } from 'react-redux';
import { storeAuthUserData } from './redux/actions';
import Config from "./config/config";

// const oidcConfig = {
//   onSignIn: async (user) => {
//     // console.log(user);
//   },
//   authority: Config.auth.ilevel.authority,
//   redirectUri: Config.auth.ilevel.redirectUri,
//   clientId: Config.auth.ilevel.clientId,
//   clientSecret: Config.auth.ilevel.clientSecret,
//   scope: "openid profile email",
//   responseType: "code",
//   automaticSilentRenew: true
// };

// const LoggedIn = () => {
//   const dispatch = useDispatch();
//   const auth = useAuth();

//   useEffect(() => {
//     if (auth && auth.userData) {
//       dispatch(storeAuthUserData(auth.userData));
//     }
//   }, [auth, dispatch]);

//   if (auth && auth.userData) {
//     return <Redirect to="/load/IdVerificationPersona" />;
//   } else {
//     return <div>Authenticating...</div>;
//   }
// };

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route exact path="/form-phone" component={FormPhone} />
          <Route exact path="/validate-phone" component={ValidatePhone} />
          <Route exact path="/form-email" component={FormEmail} />
          <Route exact path="/validation-listening" component={ValidateEmail} />
          <Route exact path="/email-validated" component={EmailValidated} />
          <Route exact path="/form-other" component={FormMatchOtherFields} />
          <Route exact path="/form-address" component={FormAddress} />
          <Route exact path="/form-password" component={FormPassword} />
          <Route exact path="/confirmation" component={Confirmation} />
          <Route exact path="/match" component={Match} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/catalog" component={Catalog} />
          <Route exact path="/course-requirements" component={CourseRequirements} />
          <Route exact path="/catalog/course-details/:id" component={CourseDetail} />
          <Route exact path="/catalog/path-details/:id" component={PathDetail} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/my-profile" component={MyProfile} />
          <Route exact path="/training-history" component={TrainingHistory} />
          <Route exact path="/edit-my-profile" component={EditMyProfile} />
          <Route exact path="/employment" component={Employment} />
          <Route exact path="/payment-methods" component={PaymentMethods} />
          <Route exact path="/security" component={Security} />
          <Route exact path="/drivers-license" component={DriversLicense} />
          {/* <AuthProvider {...oidcConfig}> */}
          {/* <Route exact path="/oidc-callback" component={LoggedIn} /> */}
          <Route exact path="/load/:componentId" component={EmbeddedAppComponent} />
          {/* </AuthProvider> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
