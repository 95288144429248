// Package Dependencies
import { all } from 'redux-saga/effects';
import { RetrieveStudentIDVerificationInquirySaga } from './Student/RetrieveStudentIDVerificationInquiry';

// Export
export default function* RootSaga() {
  yield all([
    ...RetrieveStudentIDVerificationInquirySaga
  ]);
}
