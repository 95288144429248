import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Disclosure,
  Menu,
  Transition,
} from '@headlessui/react';

import cartItems from '../../data/CartItems';
import Avatar from '../Avatar/Avatar';
import BreakPoints from '../BreakPoints/BreakPoints';
import { Logo } from '../Logo/Logo';

const user = {
  name: 'Tom Cook',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
  // { name: 'My Learning', icon: <i className='fa-regular fa-tachometer'></i>, href: '/dashboard', current: true },
  { name: 'ID Auth', icon: <i className='fa-regular fa-book-open'></i>, href: '/course-requirements', current: false },
  { name: 'Shopping Cart', icon: <i className="fa-regular fa-cart-shopping"></i>, href: '/cart', current: false },
]
const userNavigation = [
  // { name: 'My Profile', href: '/#/my-profile', icon: <i className="fa-light fa-user"></i> },
  // { name: 'Administration', href: 'http://localhost:3001/', icon: <i className="fa-light fa-cog"></i> },
  // { name: 'Support', href: '#', icon: <i className="fa-light fa-question-circle"></i> },
  // { name: 'Sign out', href: '/#/', icon: <i className="fa-light fa-sign-out"></i> },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TopNav() {
  return (
      <div>
        <Disclosure as="nav" className="bg-white shadow md:shadow-sm">
          {({ open }) => (
            <>
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-17">
                  <div className="flex items-center">
                    <div className="w-135px mr-2 h-40px">
                      <NavLink to="/dashboard">
                        <Logo />
                      </NavLink>
                    </div>
                    <div className="hidden lg:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            className="text-gray-600 hover:bg-blue-50 px-3 py-5 flex flex-row items-center last:hidden"
                            activeClassName="!text-blue-500 border-b-2 border-blue-500"
                          >
                            <div className="mr-2 text-lg">{item.icon}</div>
                            <div>{item.name}</div>
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden lg:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <BreakPoints display="false" />

                      {/* Shopping Cart */}
                      {cartItems.length > 0 ?
                        (
                          <NavLink to="/cart">
                            <div className="h-9 w-9 bg-blue-400 rounded-full flex items-center justify-center relative">
                              <div className="absolute -top-2px -right-2px bg-red-bright rounded-full h-[12px] w-[12px] border border-white">
                              </div>
                              <i className="fa-regular fa-shopping-cart text-white"></i>
                            </div>
                          </NavLink>
                      ) : (
                        <NavLink to="/cart">
                          <div className="h-9 w-9 bg-gray-500 rounded-full flex items-center justify-center">
                            <i className="fa-regular fa-shopping-cart text-white text-lg"></i>
                          </div>
                        </NavLink>
                      )}

                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-5 relative">
                        <div>
                          <Menu.Button className="max-w-xs rounded-full flex items-center focus:outline-none focus:ring-none">
                            <span className="sr-only">Open user menu</span>
                            <Avatar img={user.imageUrl} size={35} color="gray-500" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? 'bg-blue-50' : '',
                                      'block px-4 py-2 text-blue-500 last:border-t border-blue-100 flex flex-row'
                                    )}
                                  >
                                    <div className="mr-2">{item.icon}</div>
                                    <div>{item.name}</div>
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex lg:hidden items-center">



                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-bhite inline-flex items-center justify-center p-2 hover:text-blue-500">
                      <BreakPoints display="false" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <i className="fa-light fa-times text-xl w-4"></i>
                      ) : (
                        <i className="fa-light fa-bars text-xl w-4"></i>
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={`/#${item.href}`}
                      className={classNames(
                        item.current ? 'bg-blue-100 text-blue-500' : 'text-blue-500 hover:bg-blue-100',
                        'block px-3 py-2 rounded-md text-base flex flex-row items-center items-center'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                    {item.name === "Shopping Cart" && cartItems.length > 0
                      ? (<div className="mr-1 bg-red-bright rounded-full h-[12px] w-[12px] border border-white"></div>)
                      : null
                    }
                    <div className="mr-2 text-lg">{item.icon}</div>
                    <div>{item.name}</div>

                    </Disclosure.Button>
                  ))}
                </div>

                {/* Profile */}
                <div className="pt-4 pb-3 border-t border-gray-300">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="leading-none">{user.name}</div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block px-3 py-2 rounded-md text-base text-blue-500 hover:bg-blue-100"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>

              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

      </div>
  )
}
