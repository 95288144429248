import React, { Component } from "react";
import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Button from "@alliancesafetycouncil/asc-button";
import { BreadCrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import TopNav from '../../components/Navigation/TopNav';

class DriversLicense extends Component {

	constructor(props) {
		super(props);
		this.state = { value: '' };
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		this.setState({ value: event.target.value });
	}

	render() {

		const links = [
			{
				title: "My Profile",
				link: "/#/my-profile"
			},
			{
				title: "Driver's License",
			},
		];

		const data = {
			number: '129847664',
			state: 'Pennsylvania',
			expiration: '04/10/2026',
			class: 'M',
			idCard: false
		};

		return (
			<div className="flex flex-col bg-blue-50 h-screen">
				<div className="fixed w-full z-20">
					<TopNav />
				</div>
				<div className="spacer-for-nav h-16"></div>

				{/* Page Title wrapper */}
				<div className="flex-initial mx-4 mt-4 mb-1 flex flex-col">
					<div className="ml-2 my-2">
						<BreadCrumbs crumbs={links} />
					</div>

					<PageTitle>
						<i className="fal fa-id-card text-orange-500 mr-2"></i>Driver's License
					</PageTitle>
				</div>
				{/* Main Card wrapper */}
				<div className="m-3">
					<MainCard type="main">
						<form className="flex flex-col">
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-5">

								<div>
									<div className="mb-2 text-sm font-medium text-gray-700">Number</div>
									<div>
										<input
											type="text"
											defaultValue={data.number}
											onChange={this.handleChange}
											id="number"
											className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50" />
									</div>
								</div>

								<div>
									<div className="mb-2 text-sm font-medium text-gray-700">State</div>
									<div>
										<select class="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 h-10 bg-white text-gray-900 hover:bg-blue-50">
											<option>{data.state}</option>
											<option>State #2</option>
											<option>Sate #3</option>
										</select>
									</div>
								</div>

								<div>
									<div className="mb-2 text-sm font-medium text-gray-700">Expiration Date</div>
									<div>
										<input
											type="text"
											defaultValue={data.expiration}
											onChange={this.handleChange}
											id="expiration"
											className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50" />
									</div>
								</div>

								<div>
									<div className="mb-2 text-sm font-medium text-gray-700">Class</div>
									<div>
										<select class="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 h-10 bg-white text-gray-900 hover:bg-blue-50">
											<option>{data.class}</option>
											<option>C</option>
											<option>D</option>
										</select>
									</div>
								</div>

								<div>
									<div class="form-check flex items-center">
										<input type="checkbox" id="idCard" name="idCard" value="true" className="mr-2 h-5 w-5 rounded border border-gray-400 shadow-sm" />
										<label class="text-sm font-medium text-gray-700" for="flexCheckChecked">
											This is a state issued id, not a driver's license.
										</label>
									</div>
								</div>


							</div>
							<div className="flex flex-row mt-5">
								<div className="mr-3">
									<Button type="save" size="md" href="/#/my-profile">
										Save
									</Button>
								</div>
								<div>
									<Button type="tertiary" size="md" href="/#/my-profile">
										Cancel
									</Button>
								</div>
							</div>

						</form>
					</MainCard>

				</div>
			</div>
		);
	}
}

export default DriversLicense;
