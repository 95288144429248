import React from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

const menuitems = [
	{
    "item": "Edit",
		"link": "#"
  },
	{
    "item": "Remove",
		"link": "#"
  },
]

const More = () => {
    return (
      <Menu as="div" className="relative text-left">
        <div>
          <Menu.Button className="w-full px-2 py-2 bg-white text-gray-500">
            <div className="text-center">
              <i class="fas fa-ellipsis-v"></i>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="w-32 absolute right-6 top-2 rounded-lg shadow-lg bg-white border border-gray-300">

					{menuitems.map((menuitems) => (
								<Menu.Item>
									<Link to={menuitems.link} className="bg-white hover:bg-blue-50 pt-2 pb-2 px-4 block whitespace-no-wrap text-sm rounded-lg">
										{menuitems.item}
									</Link>
								</Menu.Item>
					))}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

export default More;
