import React from 'react';
import { NavLink } from 'react-router-dom';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';

import { Logo } from '../../components/Logo/Logo';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const data = {progress: "40%"}

const EmailValidated = () => {
  return (
    <div className="p-2 md:p-3">
      <div className="w-[175px] mt-6 mb-8 mx-auto">
        <Logo />
      </div>
      <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto pb-10">
        <div>
          <ProgressBar completed={data.progress} />
        </div>
        <MainCard type="main">
          <div className="grid grid-cols-1 gap-6">
            <div className="mt-6 text-2xl">Create Account</div>
              <div className="text-green-500 flex flex-row items-center p-3 rounded-lg bg-green-100">
                <div>
                  <i class="fas fa-check-circle text-xl mr-1"></i>
                </div>
                <div>
               Email validated. You may continue.
               </div>
             </div>
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <Button type="primary" size="md" href="/#/form-other" disabled="disabled">Continue</Button>
              </div>
              <div className="mr-3">
                <Button type="tertiary" size="md" href="/#/form-email">Back</Button>
              </div>
            </div>
          </div>
        </MainCard>
        <div className="flex flex-row justify-center mt-10">
          <div className="mx-3"><NavLink to="/match">Match</NavLink></div>
        </div>
      </div>
    </div>
  );
};

export default EmailValidated;
