import React from "react";
import "./EmbeddedAppComponent.module.css";
import { useParams } from "react-router-dom";
import IDVerificationPersona from "../IDVerification/IDVerificationPersona";

const componentMap = {
  IdVerificationPersona: IDVerificationPersona,
};

const EmbeddedAppComponent = React.memo(() => {
  const { componentId } = useParams();
  const DynamicComponent = componentMap[componentId] || (() => <div>Invalid Component ID</div>);

  // ----- INITIALIZE: BEGIN ----------------------------------------------------------------------------
  // Console Logging
  console.info(`Initializing...`);
  console.info(`Dynamic Component ID: ${componentId}`);
  // ----- INITIALIZE: END ------------------------------------------------------------------------------
  
  return (
    <div>
      <DynamicComponent />
    </div>
  );
});

export default EmbeddedAppComponent;