import React from "react";
import {Link} from 'react-router-dom';

export const ProfilePanel = ({title, icon, subTitle, description, link}) => {

  return (
    <div>
      <Link to={link}>
        <div className="rounded-lg p-4 bg-blue-50 h-full">
          <div className="flex flex-row">
            <div className="flex h-12 w-12 bg-blue-500 text-white rounded-full justify-center items-center">
              <div>
                <i className={`text-xl ${icon}`}></i>
              </div>
            </div>
            <div className="ml-2 grid grid-cols-1">
              <div className="text-lg font-medium text-gray-900 flex items-center">
                {title}
              </div>

              {subTitle == null ? null : (
                <div className="text-gray-400 flex items-center">
                {subTitle}
                </div>
              )}

            </div>
          </div>
          <div className="border-t border-blue-200 my-3" />
          <div className="text-gray-900">
            {description}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProfilePanel;
