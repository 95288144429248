// Retrieve Student ID Verification Inquiry Request
export const RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST = 'RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST'
export function retrieveStudentIDVerificationInquiryRequest(data) {
  return { type: RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST, data }
}

// Retrieve Student ID Verification Inquiry Error
export const RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_ERROR = 'RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_ERROR'
export function retrieveStudentIDVerificationInquiryError(data) {
  return { type: RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_ERROR, data }
}

// Retrieve Student ID Verification Inquiry Response
export const RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_RESPONSE = 'RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_RESPONSE'
export function retrieveStudentIDVerificationInquiryResponse(data) {
  return { type: RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_RESPONSE, data }
}