import React, { Component } from "react";
import { Link } from "react-router-dom";
import Training from "../../data/training";
import { AgGridReact } from "ag-grid-react";
import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Button from "@alliancesafetycouncil/asc-button";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { BreadCrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
// import TopNav from '../../components/Navigation/TopNav';
import TopNav from '../../components/Navigation/TopNav';

import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
// import FilterAnything from "@alliancesafetycouncil/asc-filter-anything";
import FilterAnything from "../../components/Form/FilterAnything";
import "../../assets/css/ag-grid-overrides.css";

class TrainingHistory extends Component {

	constructor(props) {
		super(props);
		this.state = {
			defaultColDef: {
				sortable: true,
				filter: true,
				columns: false,
				quickFilterText: null
			},
			columnDefs: [
				{
					headerName: "Course",
					field: "course_name",
					width: 350,
					cellRendererFramework: function (params) {
						return <Link to="#">{params.value}</Link>
					},
				},
				{
					headerName: "ASC Course ID",
					field: "course_id",
					flex: 1,
					filter: "agNumberColumnFilter"
				},
				{
					headerName: "Type",
					field: "course_type",
					flex: 1
				},
				{
					headerName: "Date",
					field: "date",
					flex: 1,
					filter: "agDateColumnFilter"
				},
				{
					headerName: "Location",
					field: "location",
					flex: 1
				},
				{
					headerName: "Expiration",
					field: "expiration",
					flex: 1,
					filter: "agDateColumnFilter"
				}
			],
			rowData: Training
		};
	}

	onQuickFilterText = (event) => {
		this.setState({ quickFilterText: event.target.value });
	};

	onGridReady(params) {
		this.api.sizeColumnsToFit();
	}

	render() {

		const links = [
			{
				title: "My Profile",
				link: "/#/my-profile"
			},
			{
				title: "Training History",
			},
		];

		return (
			<div className="flex flex-col bg-blue-50 h-screen">
				<div className="fixed w-full z-20">
					<TopNav />
				</div>
				<div className="spacer-for-nav h-20"></div>

				{/* Page Title wrapper */}
				<div className="flex-initial mx-4 mt-4 mb-1 flex flex-col">
					<div className="ml-2 my-2">
						<BreadCrumbs crumbs={links} />
					</div>
					<PageTitle>
						<i className="fal fa-users-class text-orange-500 mr-2"></i>Training History
					</PageTitle>
				</div>
				{/* Main Card wrapper */}
				<div className="m-3 h-full">
					<MainCard type="main">
						{/* Filters */}
						<form className="mb-4" action="#" method="POST">
							<div className="mb-1 flex flex-row">
								<div className="w-1/3">
									<FilterAnything onChange={this.onQuickFilterText} />
								</div>
								<div className="w-1/3 ml-4">
									<Button type="secondary" size="md" href="#">
										Export
									</Button>
								</div>
							</div>
						</form>
						{/* AG-Grid */}
						<div className="ag-theme-alpine w-full h-full">
							<AgGridReact
								columnDefs={this.state.columnDefs}
								rowData={this.state.rowData}
								defaultColDef={this.state.defaultColDef}
								quickFilterText={this.state.quickFilterText}
								onGridReady={this.onGridReady}
							>
							</AgGridReact>
						</div>
					</MainCard>

				</div>
			</div>
		);
	}
}

export default TrainingHistory;
