import React, { useState, useEffect } from "react";

const Delayed = ({ waitBeforeShow, children }) => {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHidden(false);
    }, waitBeforeShow);

    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return hidden ? null : children;
};

export const Loading = ({ delay }) => {
  return (
    <Delayed waitBeforeShow={delay}>
      <i className="fal fa-spinner-third animate-spin fa-4x text-blue-500"></i>
    </Delayed>
  );
};

export default Loading;
