import React, { Component } from "react";
import Button from "@alliancesafetycouncil/asc-button";
import Uploader from "../../components/Upload/Uploader";

const user = {
  name: 'Tom Cook',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

class Upload extends Component {

  constructor(props) {
    super(props);
    this.state = { value: '' };
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      currentComponent: 'EditPhoto',
    };
  }

  switchComponent = (componentName) => {
    this.setState({
      currentComponent: componentName,
    });
  };

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  render() {

    const EditPhoto = () => {
      return (
        <div className="flex flex-row gap-6 justify-center mb-3">
          <div
            className="bg-blue-100 rounded-xl h-28 w-40 flex flex-col items-center justify-center cursor-pointer"
            onClick={() => this.switchComponent('TakePhoto')}>
            <div>
              <i className="fal fa-camera text-blue-500 text-4xl"></i>
            </div>
            <div className="mt-1">
              Take Photo
            </div>
          </div>
          <div
            className="bg-blue-100 rounded-xl h-28 w-40 flex flex-col items-center justify-center cursor-pointer"
            onClick={() => this.switchComponent('UploadPhoto')}>
            <div>
              <i className="fal fa-upload text-blue-500 text-4xl"></i>
            </div>
            <div className="mt-1">
              Upload Photo
            </div>
          </div>
        </div>
      )
    }

    const TakePhoto = () => {
      return (
        <div className="flex flex-col gap-6 justify-center items-center mb-3">
          <div>
            <img
              className="inline-block rounded-full h-72 w-72"
              src={user.imageUrl}
              alt=""
            />
          </div>
          <div className="flex flex-row gap-3">
            <Button type="save" size="md" onClick={() => this.switchComponent('Confirmation')}>
              <i class="fa-regular fa-camera mr-1"></i> Take Picture
            </Button>
            <Button type="tertiary" size="md" onClick={() => this.switchComponent('EditPhoto')}>
              Back
            </Button>
          </div>
        </div>
      )
    }

    const Confirmation = () => {
      return (
        <div className="grid grid-cols-1 gap-2">
          <div className="text-green-500 text-6xl flex justify-center">
            <div className="bg-green-100 flex rounded-full p-2">
              <i class="fa-solid fa-circle-check"></i>
            </div>
          </div>
          <div className="text-green-500 text-2xl text-center">
            Photo Updated
          </div>
          <div className="text-gray-600 text-center">
            You successfully updated your photo and can now view it in your profile.
          </div>
          <div className="flex justify-center my-6">
            <Button type="primary" size="md">
              Close
            </Button>
          </div>
        </div>
      )
    }

    const { currentComponent } = this.state;

    let componentToRender;

    switch (currentComponent) {
      case 'EditPhoto':
        componentToRender = <EditPhoto />;
        break;
      case 'TakePhoto':
        componentToRender = <TakePhoto />;
        break;
      case 'UploadPhoto':
        componentToRender = 
          <div onClick={() => this.switchComponent('Confirmation')}>
            <Uploader />
          </div>;
        break;
      case 'Confirmation':
        componentToRender = <Confirmation />;
        break;
      default:
        componentToRender = null;
    }

    return (
      <div>
        {componentToRender}
      </div>
    );
  }
}

export default Upload;
