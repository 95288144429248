import React from "react";
import { AMXLogo, MCLogo, VisaLogo, DiscoverLogo } from "./Logos";
import More from "../More/More";

const Circle = () => {
  return <div className="w-2 h-2 bg-gray-900 rounded-full mx-1px"></div>;
};

const CardType = {
  amx: "American Express",
  mc: "Master Card",
  visa: "Visa",
  discover: "Discover"
}

const CardLogo = {
  amx: <AMXLogo />,
  mc: <MCLogo />,
  visa: <VisaLogo />,
  discover: <DiscoverLogo />
}

const CreditCard = ({ primary, number, name, exMonth, exYear, type }) => {
  return (
    <div className="flex flex-row">
      {CardLogo[type]}
      <div className="flex flex-col py-4 md:p-0">
        <div className="flex flex-row items-center">
          <div>{CardType[type]} ending in</div>
          <div className="flex flex-row mx-1">
            <Circle />
            <Circle />
            <Circle />
            <Circle />
          </div>
          <div>{number}</div>
          { primary === true
            ? <div className="ml-2 text-green-500">(Primary)</div>
            : null
          }
        </div>
        <div className="text-gray-600">{name}</div>
        <div className="text-gray-600">Expires {exMonth}/{exYear}</div>
      </div>

      <div className="flex flex-grow justify-end">
        <More />
      </div>
    </div>
  );
};

export default CreditCard;
