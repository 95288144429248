import React from 'react';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';

import { Logo } from '../../components/Logo/Logo';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const progress = { percent: "100%" }

const Confirmation = () => {
  return (
    <div className="p-2 md:p-3">
      <div className="w-[175px] mt-6 mb-8 mx-auto">
        <Logo />
      </div>
      <div className="relative w-full md:w-9/12 lg:w-8/12 xl:w-7/12 mx-auto pb-10">
        <div className="absolute w-full rounded-tr-xl overflow-hidden">
          <ProgressBar progress={progress.percent} />
        </div>
        <MainCard type="main">
          <div className="grid grid-cols-1 gap-3 mt-10">
            <div className="text-green-500 text-6xl flex justify-center">
              <div className="bg-green-100 flex rounded-full p-2">
                <i class="fa-solid fa-circle-check"></i>
              </div>
            </div>
            <div className="text-green-500 text-2xl text-center">
              Thank You!
            </div>
            <div className="text-gray-600 text-center">
              We added the additional information to your account. 
            </div>
            <div className="flex justify-center my-6">
              <Button type="primary" size="md" href="/#/dashboard">
                Continue to Dashboard
              </Button>
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  );
};

export default Confirmation;
