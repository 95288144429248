// Package Dependencies
// Additional Dependencies
import {
  RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST,
  RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_RESPONSE,
  RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_ERROR
} from '../../action/Student/RetrieveStudentIDVerificationInquiry'

// Define Initial State
const INITIAL_STATE = {
  busy: false,
  processing: false,
  error: false,
  response: false,
  data: {}
}

// Retrieve Student ID Verification Inquiry Reducer
function RetrieveStudentIDVerificationInquiryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Retrieve Student ID Verification Inquiry Request
    case RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Student ID Verification Inquiry Response
    case RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_RESPONSE:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        data: action.data,
      }
    // Retrieve Student ID Verification Inquiry Error
    case RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_ERROR:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: 'Retrieve Student ID Verification Inquiry Inquiry Error'
      }
    default:
      return state
  }
}

// Export
export default RetrieveStudentIDVerificationInquiryReducer