// Package Dependencies
import { call, put, takeLatest } from "redux-saga/effects";

// Action Dependencies
import {
  RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST,
  retrieveStudentIDVerificationInquiryError,
  retrieveStudentIDVerificationInquiryResponse,
} from "../../action/Student/RetrieveStudentIDVerificationInquiry";

// Additional Dependencies
import Axios from "../../utility/Axios";

// Function Handle Create Student ID Verification Inquiry
function* handleRetrieveStudentIDVerificationInquiry(action) {
  try {
    // Define Resource
    const resource = `/idverification/inquiriy/${action.data.tenant_id}/${action.data.company_id}/${action.data.student_id}/${action.data.inquiry_id}`;
    // Create Student ID Verification Inquiry Request
    const response = yield call(Axios.get_request, `${resource}`);
    // Create Student ID Verification Inquiry Response
    yield put(retrieveStudentIDVerificationInquiryResponse(response.data));
  } catch (error) {
    // Create Student ID Verification Inquiry Error
    yield put(retrieveStudentIDVerificationInquiryError(error));
  }
}

// Export
export const RetrieveStudentIDVerificationInquirySaga = [
  takeLatest(
    RETRIEVE_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST,
    handleRetrieveStudentIDVerificationInquiry
  ),
];
