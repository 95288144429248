// Package Dependencies
import { combineReducers } from 'redux';

// Additional Dependencies
import RetrieveStudentIDVerificationInquiryReducer from './Student/RetrieveStudentIDVerificationInquiry';

// Combine Reducers
const RootReducer = combineReducers({
  retrieveStudentIDVerificationInquiryReducer: RetrieveStudentIDVerificationInquiryReducer
});

// Export
export default RootReducer;
