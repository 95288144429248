
const Cards = [
	{
        "name": "Johnathon Williams",
        "card_type": "mc",
				"last4": 1234,
				"primary": true,
				"exMonth": 1,
				"exYear": 26
    },
    {
        "name": "Johnathon Williams",
        "card_type": "visa",
				"last4": 5678,
				"primary": false,
				"exMonth": 1,
				"exYear": 22
	},
    {
        "name": "Johnathon Williams",
        "card_type": "amx",
				"last4": 4957,
				"primary": false,
				"exMonth": 1,
				"exYear": 26
	},
    {
        "name": "Johnathon Williams",
        "card_type": "discover",
				"last4": 4922,
				"primary": false,
				"exMonth": 1,
				"exYear": 26
	}
]

export default Cards;
