import React, { Component } from "react";
import { Link } from "react-router-dom";
import CompanyList from "../../data/companies";
import { AgGridReact } from "ag-grid-react";
import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Button from "@alliancesafetycouncil/asc-button";
import { BreadCrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import TopNav from '../../components/Navigation/TopNav';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
// import FilterAnything from "@alliancesafetycouncil/asc-filter-anything";
import FilterAnything from "../../components/Form/FilterAnything";
import "../../assets/css/ag-grid-overrides.css";

class Employment extends Component {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			defaultColDef: {
				sortable: true,
				filter: true,
				columns: false,
				quickFilterText: null
			},
			columnDefs: [
				{
					headerName: "Company",
					field: "company_name",
					flex: 1,
					sort: "asc",
					cellRendererFramework: function (params) {
						return <Link to="#">{params.value}</Link>
					},
				},
				{
					headerName: "Company ID",
					field: "company_id",
					filter: "agNumberColumnFilter",
					width: 65
				}
			],
			rowData: CompanyList
		};
	}

	handleChange(event) { this.setState({ value: event.target.value }); }
	handleSubmit(event) {
		alert('A name was submitted: ' + this.state.value);
		event.preventDefault();
	}

	onQuickFilterText = (event) => {
		this.setState({ quickFilterText: event.target.value });
	};

	onGridReady(params) {
		this.api.sizeColumnsToFit();
	}

	render() {

		const data = {
			employer: 'Eastman Kodak Chemical, Inc.',
			manager: "Brian Smith",
			trainingDirector: 'Brian Smith',
			trade: 'Construction',
			timeTrade: '7 years',
			training: 'Welding',
			timeTraining: '5 years',
			union: ''
		};

		const links = [
			{
				title: "My Profile",
				link: "/#/my-profile"
			},
			{
				title: "Employment",
			},
		];

		return (
			<div className="flex flex-col bg-blue-50 h-screen">
				<div className="fixed w-full z-10">
					<TopNav />
				</div>
				<div className="spacer-for-nav mt-16"></div>
				{/* Page Title wrapper */}
				<div className="flex-initial mx-4 mt-4 mb-1 flex flex-col">
					<div className="ml-2 my-2">
						<BreadCrumbs crumbs={links} />
					</div>
					<PageTitle>
						<i className="fal fa-briefcase text-orange-500 mr-2"></i>Edit Employment
					</PageTitle>
				</div>
				{/* Main Card wrapper */}
				<div className="m-3">
					<MainCard type="main">
						<form>
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
								{/* Start Employer Dropdown */}
								<div>
									<div className="mb-2 text-sm font-medium text-gray-700">Employer</div>
									<div>
										<Menu as="div" className="relative inline-block text-left w-full">
											<div>
												<Menu.Button className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50">
													<div className="flex-grow text-left">{data.employer}</div>
													<div className="ml-3"><i class="far fa-angle-down text-gray-500"></i></div>
												</Menu.Button>
											</div>
											<Transition
												as={Fragment}
												enter="transition ease-out duration-100"
												enterFrom="transform opacity-0 scale-95"
												enterTo="transform opacity-100 scale-100"
												leave="transition ease-in duration-75"
												leaveFrom="transform opacity-100 scale-100"
												leaveTo="transform opacity-0 scale-95"
											>
												<Menu.Items className="h-96 p-3 absolute left-0 mt-2 w-full rounded shadow-lg bg-white border border-gray-300">
													<div className="mb-3">
														<FilterAnything onChange={this.onQuickFilterText} />
													</div>
													<div className="ag-theme-alpine h-72">
														<AgGridReact
															columnDefs={this.state.columnDefs}
															rowData={this.state.rowData}
															defaultColDef={this.state.defaultColDef}
															quickFilterText={this.state.quickFilterText}
															onGridReady={this.onGridReady}
														>
														</AgGridReact>
													</div>
												</Menu.Items>
											</Transition>
										</Menu>
									</div>
								</div>
								{/* End Employer Dropdown */}
								<div className="col-start-1">
									<div className="mb-2 text-sm font-medium text-gray-700">Manager</div>
									<div>
										<input
											type="text"
											defaultValue={data.manager}
											onChange={this.handleChange}
											id="manager"
											className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50" />
									</div>
								</div>

								<div>
									<div className="mb-2 text-sm font-medium text-gray-700">Training Director</div>
									<div>
										<input
											type="text"
											defaultValue={data.trainingDirector}
											onChange={this.handleChange}
											id="trainingDirector"
											className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50" />
									</div>
								</div>

								<div>
									<div className="mb-2 text-sm font-medium text-gray-700">Trade</div>
									<div>
										<select class="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50">
											<option>{data.trade}</option>
											<option>Trade #2</option>
											<option>Trade #3</option>
										</select>
									</div>
								</div>

								<div>
									<div className="mb-2 text-sm font-medium text-gray-700">Time in Trade</div>
									<div>
										<select class="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50">
											<option>{data.timeTrade}</option>
											<option>1 years</option>
											<option>2 years</option>
										</select>
									</div>
								</div>

								<div>
									<div className="mb-2 text-sm font-medium text-gray-700">Type of Training</div>
									<div>
										<input
											type="text"
											defaultValue={data.training}
											onChange={this.handleChange}
											id="training"
											className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50" />
									</div>
								</div>

								<div>
									<div className="mb-2 text-sm font-medium text-gray-700">Time in Training</div>
									<div>
										<select class="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50">
											<option>{data.timeTraining}</option>
											<option>1 years</option>
											<option>2 years</option>
										</select>
									</div>
								</div>

								<div>
									<div className="mb-2 text-sm font-medium text-gray-700">Union (Optional)</div>
									<div>
										<input
											type="text"
											defaultValue={data.union}
											onChange={this.handleChange}
											id="union"
											className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50" />
									</div>
								</div>

							</div>
							<div className="flex flex-row mt-5">
								<div className="mr-3">
									<Button type="save" size="md" href="/#/my-profile">
										Save
									</Button>
								</div>
								<div>
									<Button type="tertiary" size="md" href="/#/my-profile">
										Cancel
									</Button>
								</div>
							</div>

						</form>
					</MainCard>

				</div>
			</div>
		);
	}
}

export default Employment;
