import React, { Component } from "react";
import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Button from "@alliancesafetycouncil/asc-button";
import { BreadCrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import CreditCard from "../../components/CreditCards/creditCard";
import TopNav from '../../components/Navigation/TopNav';
import Cards from "../../data/creditCards";

const data = Cards;

class PaymentMethods extends Component {
  render() {

    const links = [
			{
				title: "My Profile",
				link: "/#/my-profile"
			},
			{
				title: "Payment Methods",
			},
		];

    return (
      <div className="flex flex-col bg-blue-50 h-screen">
        <div className="fixed w-full z-10">
					<TopNav />
				</div>
				<div className="spacer-for-nav mt-16"></div>
        {/* Page Title wrapper */}
        <div className="flex-initial mx-4 mt-4 mb-1 flex flex-col">
					<div className="ml-2 my-2">
						<BreadCrumbs crumbs={links} />
					</div>
          <PageTitle>
            <i className="fal fa-credit-card text-orange-500 mr-2"></i>Payment
            Methods
          </PageTitle>
        </div>
        {/* Main Card wrapper */}
        <div className="m-3">
          <MainCard type="main">
						<div className="pb-4 border-b border-gray-300 mb-4 flex flex-row items-end">
							<div className="text-xl flex flex-grow">Credit Cards</div>
              <Button type="primary" size="md" href="#">Add Credit Card</Button>
						</div>
						<div>
              {data.map((data) => (
                data.name !== null
                  ? (
                    <div className="pb-4 border-b border-gray-300 mb-4">
                      <CreditCard
                        type={data.card_type}
                        primary={data.primary}
                        number={data.last4}
                        name={data.name}
                        exMonth={data.exMonth}
                        exYear={data.exYear} />
                    </div>
                    )
                  : null
              ))}
						</div>
					</MainCard>
        </div>
      </div>
    );
  }
}

export default PaymentMethods;
