import React from "react";
import AMXImage from "../../assets/images/amx.jpg";
import MCImage from "../../assets/images/mastercard.png";
import VisaImage from "../../assets/images/visa.png";
import DiscoverImage from "../../assets/images/discover.png";

export const AMXLogo = () => {
  return (
    <div
      style={{ backgroundColor: "#016FD0" }}
      className="h-14 min-h-14 w-20 flex justify-center items-center mr-4"
    >
      <img src={AMXImage} alt="American Express" className="h-14" />
    </div>
  );
};

export const MCLogo = () => {
  return (
    <div
      style={{ backgroundColor: "#f0efeb" }}
      className="h-14 py-1 w-20 flex justify-center items-center mr-4"
    >
      <img src={MCImage} alt="American Express" className="h-10" />
    </div>
  );
};

export const VisaLogo = () => {
  return (
    <div
      style={{ backgroundColor: "#0353a5" }}
      className="h-14 py-5 w-20 flex justify-center items-center mr-4"
    >
      <img src={VisaImage} alt="American Express" className="h-4" />
    </div>
  );
};

export const DiscoverLogo = () => {
  return (
    <div
      style={{ backgroundColor: "#FFFFFF" }}
      className="h-14 w-20 flex justify-center items-center mr-4"
    >
      <img src={DiscoverImage} alt="American Express" className="h-12" />
    </div>
  );
};
