import Button from "@alliancesafetycouncil/asc-button";

const Uploader = ({onClick}) => {
    return (
      <div className="grid grid-cols-1 gap-2 rounded-lg border-dashed border-2 border-gray-300 p-3">
        <div className="text-gray-300 text-6xl flex justify-center">
          <i class="fa-light fa-cloud-arrow-up"></i>
        </div>
        <div className="text-gray-600 text-center">
          <div>Drag and drop your file here.</div>
          <div>Or...</div>
        </div>
        <div className="flex justify-center my-3">
          <Button type="primary" size="md" onClick={onClick}>
            Choose File
          </Button>
        </div>
      </div>
    )
  }

  export default Uploader;
