import React, { Component } from "react";
import MainCard from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Button from "@alliancesafetycouncil/asc-button";
import Avatar from "../../components/Avatar/Avatar";
import BackgroundImg from "../../assets/images/background.png";
import TopNav from '../../components/Navigation/TopNav';
import BasicModal from "../../components/Modal/BasicModal";
import { BreadCrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import Upload from "../../components/Upload/Upload";

const user = {
  name: 'Tom Cook',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

const CameraButton = () => {
  return (
    <div className="z-10 absolute right-0 bottom-0 border-2 border-white h-35px w-35px bg-gray-500 text-white rounded-full flex justify-center items-center">
      <div><i className="fal fa-camera text-lg"></i></div>
    </div>
  )
}

class EditMyProfile extends Component {

  constructor(props) {
    super(props);
    this.state = { value: '' };
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      currentComponent: 'EditPhoto',
    };
  }

  switchComponent = (componentName) => {
    this.setState({
      currentComponent: componentName,
    });
  };

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  render() {

    const data = {
      firstName: 'Johnathon',
      lastName: 'Williams',
      title: 'Welder',
      displayName: '',
      email: 'jwilliams@email.com',
      phone: '443-555-1212',
      fax: '',
      address1: '123 E Main Street',
      address2: '',
      city: 'Lancaster',
      state: 'Pennsylvania',
      zip: '12345'
    };

    const links = [
      {
        title: "My Profile",
        link: "/#/my-profile"
      },
      {
        title: "Edit My Profile",
      },
    ];

    return (
      <div className="flex flex-col bg-blue-50 h-screen">
        <div className="fixed w-full z-10">
          <TopNav />
        </div>
        <div className="spacer-for-nav mt-16"></div>
        {/* Page Title wrapper */}
        <div className="flex-initial mx-4 mt-4 mb-1 flex flex-col">
          <div className="ml-2 my-2">
            <BreadCrumbs crumbs={links} />
          </div>
          <PageTitle>
            <i className="fal fa-user text-orange-500 mr-2"></i>Edit My Profile
          </PageTitle>
        </div>
        {/* Main Card wrapper */}
        <div className="m-3 pb-3">
          <MainCard type="main">
            <form>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div className="col-span-1 lg:col-span-2">
                  <div
                    style={{
                      backgroundImage: `url(${BackgroundImg})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      height: "95px",
                      minHeight: "95px",
                    }}
                    className="rounded-t-lg relative"
                  >
                    <div className="hidden sm:block absolute bottom-3 right-2">
                      <Button type="tertiary" size="sm" href="#">
                        <i className="fal fa-camera text-lg mr-2"></i>Edit Background
                      </Button>
                    </div>
                    <div className="flex justify-center">
                      <div className="mt-4 w-32 relative">
                        <BasicModal
                          title="Edit Photo"
                          icon="camera"
                          buttonComponent={CameraButton}
                        >
                          <div>
                            <Upload />
                          </div>
                        </BasicModal>
                        <Avatar img={user.imageUrl} size={120} color="gray-500" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 lg:col-span-2 mt-3"></div>
                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    First Name
                  </div>
                  <div>
                    <input
                      type="text"
                      defaultValue={data.firstName}
                      onChange={this.handleChange}
                      id="firstName"
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>

                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    Last Name
                  </div>
                  <div>
                    <input
                      type="text"
                      defaultValue={data.lastName}
                      onChange={this.handleChange}
                      id="lastName"
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>

                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    Title
                  </div>
                  <div>
                    <input
                      type="text"
                      defaultValue={data.title}
                      onChange={this.handleChange}
                      id="title"
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>

                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    Display Name (Optional)
                  </div>
                  <div>
                    <input
                      type="text"
                      defaultValue={data.displayName}
                      onChange={this.handleChange}
                      id="displayName"
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>

                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    Email
                  </div>
                  <div>
                    <input
                      type="text"
                      defaultValue={data.email}
                      onChange={this.handleChange}
                      id="email"
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>

                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    Phone Number
                  </div>
                  <div>
                    <input
                      type="text"
                      defaultValue={data.phone}
                      onChange={this.handleChange}
                      id="phone"
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>

                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    Fax Number (Optional)
                  </div>
                  <div>
                    <input
                      type="text"
                      defaultValue={data.fax}
                      onChange={this.handleChange}
                      id="fax"
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>

                <div className="col-start-1">
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    Address 1
                  </div>
                  <div>
                    <input
                      type="text"
                      defaultValue={data.address1}
                      onChange={this.handleChange}
                      id="address1"
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>

                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    Address 2 (Optional)
                  </div>
                  <div>
                    <input
                      type="text"
                      defaultValue={data.address2}
                      onChange={this.handleChange}
                      id="address2"
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>

                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    City
                  </div>
                  <div>
                    <input
                      type="text"
                      defaultValue={data.city}
                      onChange={this.handleChange}
                      id="city"
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>

                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    State
                  </div>
                  <div>
                    <select class="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 h-10 bg-white text-gray-900 hover:bg-blue-50">
                      <option>{data.state}</option>
                      <option>State #2</option>
                      <option>Sate #3</option>
                    </select>
                  </div>
                </div>

                <div>
                  <div className="mb-2 text-sm font-medium text-gray-700">
                    Zip Code
                  </div>
                  <div>
                    <input
                      type="text"
                      defaultValue={data.zip}
                      onChange={this.handleChange}
                      id="zip"
                      className="inline-flex w-full rounded border border-gray-400 shadow-sm px-2 py-2 bg-white text-gray-900 hover:bg-blue-50"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row mt-5">
                <div className="mr-3">
                  <Button type="save" size="md" href="/#/my-profile">
                    Save
                  </Button>
                </div>
                <div>
                  <Button type="tertiary" size="md" href="/#/my-profile">
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </MainCard>
        </div>
      </div>
    );
  }
}

export default EditMyProfile;

// class ComponentA extends React.Component {
//   render() {
//     return <div>Component A</div>;
//   }
// }

// class ComponentB extends React.Component {
//   render() {
//     return <div>Component B</div>;
//   }
// }

// class ComponentC extends React.Component {
//   render() {
//     return <div>Component C</div>;
//   }
// }